import React from 'react';
import AccuterraVideo from '../assets/video/ATMaps_hero_video_1920x640_hb2.mp4';
import HollowButton from './ui-components/hollowButton';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';

const variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
      when: 'beforeChildren',
      staggerChildren: 0.1,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
};

const children = {
  hidden: {
    opacity: 0,
    y: 50,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
};

export default function VideoHero() {
  return (
    <div className="video-player-wrapper overflow-hidden relative w-full mb-16">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={variants}
        className="absolute top-0 left-0 w-full h-full"
      >
        <div className="z-10 w-full h-full flex flex-col justify-center items-center text-center">
          <motion.p
            variants={children}
            className="my-1 md:text-7xl tracking-wide text-5xl fon font-black text-white"
          >
            Find Yourself Outdoors
          </motion.p>
          <motion.p
            variants={children}
            className="my-1 md:text-3xl text-2xl  font-extralight text-white"
          >
            maps for recreation apps
          </motion.p>
          <motion.div
            variants={children}
            className="mt-3 mb-1 grid grid-cols-2 gap-2 z-50"
          >
            <Link to={'/documentation'}>
              <HollowButton>Start Developing</HollowButton>
            </Link>
            <Link to={'/explore-map/'}>
              <HollowButton>Explore Maps</HollowButton>
            </Link>
          </motion.div>
        </div>
      </motion.div>
      <video className="video-player" loop muted autoPlay>
        <source src={AccuterraVideo} type="video/mp4" />
      </video>
    </div>
  );
}
